import { defineNuxtPlugin } from 'nuxt/app';
import { type ComputedRef } from 'vue';
import { type RouteLocationNormalized, type Router } from 'vue-router';
import { type VueI18n } from 'vue-i18n';
import { usePreferredApiKeyStore } from '@/store/preferredApiKey';
import { getCanonicalUrlFromWindowLocation, setCanonicalUrl } from '@/utils/canonical';
import prerender from '@/utils/prerender';
import { replaceLocaleRootForUrl } from '@/utils/i18n';
import { debounce } from 'lodash-es';
import { applyScrollForRoute } from '@/utils/scrolling';

// need to debounce this due to a bug in single SPA & Vue Router 4
// https://github.com/single-spa/single-spa/issues/929
const applyRouteSideEffects = debounce(async (to: RouteLocationNormalized) => {
  // @ts-ignore
  const shouldTrackPageView = to.meta?.analytics?.trackPageView ?? true;
  if (shouldTrackPageView) {
    window.analytics.onPageLoad();
  }
}, 200);

export default defineNuxtPlugin(nuxtApp => {
  const router: Router = nuxtApp.$router as Router;
  const i18n: VueI18n = nuxtApp.$i18n as VueI18n;

  const currentLocale = (i18n.locale as unknown as ComputedRef<string>).value;

  router.beforeEach(async (to, from, next) => {
    // When user is coming from dashboard with #apiKey= in the URL, we set that as the preferred API key
    const { setPreferredApiKey } = usePreferredApiKeyStore();
    if (to.hash && to.hash.startsWith('#apiKey=')) {
      const apiKey = to.hash.substring('#apiKey='.length);
      setPreferredApiKey(apiKey);

      // Remove hash from URL now, otherwise vue-router will try to scroll to `#apiKey=...`
      next({
        path: to.path,
        query: to.query,
        hash: '',
      });
      return;
    }

    // support old way of providing locale by query
    // and redirect to the correct locale URL
    if (to.query.locale) {
      const toLocale = i18n.availableLocales.includes(to.query.locale as string)
        ? (to.query.locale as string)
        : currentLocale;

      // map e.g. /en/home?locale=ja to /ja/home or /home?locale=ja to /ja/home
      const routeLocale = to.matched[0]?.meta.locale;
      // @ts-ignore
      const newPath = routeLocale ? replaceLocaleRootForUrl(to.path, routeLocale, toLocale) : `/${toLocale}${to.path}`;

      next({
        query: { ...to.query, locale: undefined },
        path: newPath,
      });
    }

    /**
     * Remove .md from the path
     * */
    if (to.path.endsWith('.md')) {
      next({
        path: to.path.replace('.md', ''),
        query: to.query,
        hash: to.hash,
        replace: true,
      });
    }

    const lowercasePath = to.path.toLowerCase();
    const hasTrailingSlash = to.path.endsWith('/');
    const query = { ...to.query };

    /**
     * Don't convert the files ending with .html extension to lowercase as it will change the file name
     * as the sdk/stitch(conversation-docs) urls are having .html files in the url
     * For example for below url with lowercase the file name will be converted to ot.html and will cause 404
     * https://developer.vonage.com/en/sdk/stitch/video-js-reference/OT.html#initPublisher
     * */
    if (!to.path.endsWith('.html') && to.path !== lowercasePath) {
      // Convert the URL to lowercase and redirect to the lowercase URL
      next({
        path: lowercasePath,
        query: to.query,
        hash: to.hash,
        replace: true,
      });
    } else if (hasTrailingSlash) {
      // Remove the trailing slash and redirect to the URL without the trailing slash
      next({ path: to.path.slice(0, -1), query, hash: to.hash, replace: true });
    } else {
      // Continue with the navigation as usual
      next();
    }
  });

  router.afterEach(async (to, from) => {
    // afterEach will always be triggered on initial load with from route of "/"
    setCanonicalUrl(getCanonicalUrlFromWindowLocation());
    // add 301 meta tags for prerender if needed
    prerender?.detectRedirect();

    // set locale from URL if it differs from current locale
    const routeLocale = to.matched[0]?.meta.locale;
    if (routeLocale && routeLocale !== currentLocale) {
      // @ts-ignore
      i18n.locale.value = routeLocale;
    }

    await applyRouteSideEffects(to);

    // go to top once page is loaded
    if (!to.meta.mfe) {
      setTimeout(async () => {
        applyScrollForRoute(to, to.path === from.path ? 'smooth' : 'instant');
      }, 0);
    }
  });

  router.onError(error => {
    // eslint-disable-next-line no-console
    console.error('Error while loading route', error);

    /**
     * When a new version of the application has been deployed, we might run into 404 errors when loading chunks (dynamic
     * imports) from the old version. In this case, we reload the page to get the new version.
     * The error is: "TypeError: error loading dynamically imported module"
     */
    if (error.message.includes('error loading dynamically imported module')) {
      window.location.reload();
    }
  });
});
