import { defineStore } from 'pinia';
import { ref, type Ref } from 'vue';

export interface BrandingStoreData {
  vendorId: string;
  vendorName: string;
  customerDashboardUrl: string;
  customerSupportUrl: string;
  customerSupportEmail: string;
}

export const useBrandingStore = defineStore('branding', () => {
  const data: Ref<BrandingStoreData | undefined> = ref();
  const setData = (value: BrandingStoreData) => {
    data.value = value;
  };

  return { data, setData };
});
