import revive_payload_client_YTGlmQWWT5 from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_VLCWq6w7BW from "/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_VTarsGAzgb from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_xKuw1km5sV from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/packages/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_piAzjeEv9X from "/app/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import plugin_ZbXcQ8JydQ from "/app/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import composition_N1rIkEnC9Q from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_dOTnCc6TUQ from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import _1_entrypoint_client_yDO8LPYpqA from "/app/packages/frontend/plugins/1.entrypoint.client.ts";
import _2_routeGards_client_6qT93iSBTl from "/app/packages/frontend/plugins/2.routeGards.client.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  _0_siteConfig_VLCWq6w7BW,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  chunk_reload_client_SeG0EjL5Ec,
  plugin_vue3_xKuw1km5sV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_piAzjeEv9X,
  plugin_ZbXcQ8JydQ,
  composition_N1rIkEnC9Q,
  i18n_dOTnCc6TUQ,
  _1_entrypoint_client_yDO8LPYpqA,
  _2_routeGards_client_6qT93iSBTl
]