export const IsProduction = process.env.NODE_ENV === 'production';
export const IsDevelopment = process.env.NODE_ENV === 'development';

export const OrgNameHeader = 'x-org-name';

export const VonageOrgID = 'vonage';

export const VonageWebsiteUrl = 'https://www.vonage.com';

export const VonageAPIStatusPageLink = 'https://vonageapi.statuspage.io/';

export const VonageRegistrationUrl = '/sign-up?icid=tryitfree_api-developer-adp_nexmodashbdfreetrialsignup_nav';
export const VonageBlogArticleSignUpURL =
  '/sign-up?icid=tryitfree_api-adp-blog_nexmodashbdfreetrialsignup_blogbannerwidget';
export const VonageBlogLandingPageSignUpURL =
  '/sign-up?icid=tryitfree_api-adp-blog_nexmodashbdfreetrialsignup_bloglandingbutton';
export const VonageHomePageRegistrationUrl =
  '/sign-up?&utm_campaign=bizdirect&attribution_campaign=bizdirect&icid=tryitfree_homepage_apidevsignup_tile&adobe_mc=MCMID%3D54513262962993135963256534253007039499%7CMCORGID%3DA8833BC75245AF9E0A490D4D%2540AdobeOrg%7CTS%3D1710447635&icid=tryitfree_api-developer-adp_nexmodashbdfreetrialsignup_nav';

export const CDNUrl = 'https://d226lax1qjow5r.cloudfront.net';

export const VividSSRScriptUrl = new URL('/assets/vivid-ssr/3.51.0/vivid-ssr.min.js?prefix=portal', CDNUrl).toString();

export const VividSSRStyleUrl = new URL('/assets/vivid-ssr/3.51.0/vivid-ssr.min.css', CDNUrl).toString();

// 400 days is the max that can be set with the new rules
export const MaxAgeForCookie = 34560000;

export const StoryblokGraphQLApi = 'https://gapi.storyblok.com/v1/api';

export const ScrollTopPadding = 8;
export const ApplicationCardImage = {
  Width: '835',
  Height: '437',
};

export const TwitterCardType = 'summary_large_image';
export const ApplicationDescription =
  'Get guides and code snippets, browse reference documentation, and more to develop connected applications with Vonage APIs and SDKs.';
export const DefaultLocale = 'en';
export const VonageHomePageBannerAnalyticsInteractionType = 'homepage announcement banner';
